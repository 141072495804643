import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'timeline', pathMatch: 'full' }, // Default route
  { path: '', loadChildren: () => import('./feature/planner/planner.module').then(m => m.PlannerModule) },
  { path: '**', redirectTo: 'timeline', pathMatch: 'full' } // Wildcard route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }