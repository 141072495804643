import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withJsonpSupport } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { AppNgZorroAntdModule } from './app-ng-zorro-antd.module';
import { AppPrimeNGModule } from './app-primeng.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { GanttModule } from './feature/planner/gantt/gantt.module';
import { PlannerModule } from './feature/planner/planner.module';
import { ConfirmationService } from 'primeng/api';

registerLocaleData(en);

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ScrollingModule,
        DragDropModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        PlannerModule
    ],
    providers: [
        provideHttpClient(withJsonpSupport()),
        { provide: NZ_I18N, useValue: en_US },
        { provide: NZ_ICONS, useValue: icons },
        ConfirmationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
